@font-face {
  font-family: cormorant;
  src: url("../asset/font/CormorantGaramond-Light.ttf");
}
@font-face {
  font-family: notoserif;
  src: url("../asset/font/NotoSerifDisplay-VariableFont_wdth,wght.ttf");
}
@font-face {
  font-family: mulish;
  src: url("../asset/font/Mulish-VariableFont_wght.ttf");
}
@font-face {
  font-family: mavenpro;
  src: url("../asset/font/MavenPro-VariableFont_wght.ttf");
}
@font-face {
  font-family: asap;
  src: url("../asset/font/Asap-Italic-VariableFont_wdth,wght.ttf");
}
@font-face {
  font-family: alata;
  src: url("../asset/font/Alata-Regular.ttf");
}
@font-face {
  font-family: cabin;
  src: url("../asset/font/Cabin-VariableFont_wdth,wght.ttf");
}
* {
  padding: 0;
  margin: 0;
  border: none;
  box-sizing: border-box;
  outline: none;
}

.App {
  height: auto;
  display: flex;
  flex-direction: column;
}
.App .wrapper-dang-ky {
  width: 1366.6px;
  height: 100%;
  background-color: #edefef;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 5;
}
.App .wrapper-dang-ky .container-dang-ky {
  width: 750px;
  height: 220px;
  border: 0.5px solid #333;
  border-radius: 10px;
  background-color: #fff;
}
.App .wrapper-dang-ky .container-dang-ky h3 {
  text-align: center;
  font-size: 26px;
  font-family: notoserif;
  margin-top: 30px;
  margin-bottom: 20px;
  font-weight: 700;
}
.App .wrapper-dang-ky .container-dang-ky p {
  width: 600px;
  text-align: center;
  font-size: 16px;
  font-family: mulish;
  margin-left: 60px;
}
.App .wrapper-header-navbar {
  width: 1366.6px;
  height: 152.2px;
  display: flex;
  flex-direction: column;
}
.App .wrapper-header-navbar .wrapper-header-logo-icon {
  width: 1366.6px;
  height: 107.2px;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  position: relative;
  z-index: 1;
}
.App .wrapper-header-navbar .wrapper-header-logo-icon .header-logo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.App .wrapper-header-navbar .wrapper-header-logo-icon .header-logo p {
  font-size: 32px;
  color: #fd6713;
  font-weight: 700;
  font-family: cormorant;
}
.App .wrapper-header-navbar .wrapper-header-logo-icon .header-icon {
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 79px;
  top: 50%;
  transform: translateY(-15px);
  width: 77px;
  height: 30px;
}
.App .wrapper-header-navbar .wrapper-header-logo-icon .header-icon .header-icon-search {
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
}
.App .wrapper-header-navbar .wrapper-header-logo-icon .header-icon .header-icon-search .container-border-search {
  width: 300px;
  height: 33px;
  border: 1px solid #fff;
  background-color: #fd6713;
  border-radius: 100px;
  position: absolute;
  right: 0;
  transform: translate(30px, 0);
  align-items: center;
}
.App .wrapper-header-navbar .wrapper-header-logo-icon .header-icon .header-icon-search .container-border-search .input-search {
  width: 250px;
  background-color: #fd6713;
  border-radius: 100px;
  margin-left: 20px;
  font-size: 12px;
  color: #fff;
  position: absolute;
  z-index: 6;
}
.App .wrapper-header-navbar .wrapper-header-logo-icon .header-icon .header-icon-search .container-border-search .input-search::-moz-placeholder {
  color: #fff;
}
.App .wrapper-header-navbar .wrapper-header-logo-icon .header-icon .header-icon-search .container-border-search .input-search::placeholder {
  color: #fff;
}
.App .wrapper-header-navbar .wrapper-header-logo-icon .header-icon .header-icon-search .container-border-search .wrapper-search-position {
  width: 300px;
  border-radius: 5px;
  background-color: #fd6713;
  position: absolute;
  top: 0;
  z-index: 5;
  display: flex;
  justify-content: center;
}
.App .wrapper-header-navbar .wrapper-header-logo-icon .header-icon .header-icon-search .container-border-search .wrapper-search-position .search-position {
  width: 280px;
  margin-top: 30px;
  border-top: 1px solid #fff;
  margin-bottom: 30px;
}
.App .wrapper-header-navbar .wrapper-header-logo-icon .header-icon .header-icon-search .search-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fd6713;
  border-radius: 100px;
}
.App .wrapper-header-navbar .wrapper-header-logo-icon .header-icon .header-icon-search .search-icon svg {
  width: 20px;
  height: 20px;
}
.App .wrapper-header-navbar .wrapper-header-logo-icon .header-icon .header-icon-shopping-cart {
  position: absolute;
  right: 0;
}
.App .wrapper-header-navbar .wrapper-header-logo-icon .header-icon .header-icon-shopping-cart svg {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.App .wrapper-header-navbar .wrapper-header-logo-icon .header-icon .header-icon-shopping-cart .header-icon-shopping-cart-quantity {
  width: 18px;
  border: 1px solid #fff;
  border-radius: 10px;
  background-color: #db2c2c;
  color: #fff;
  text-align: center;
  position: absolute;
  top: -5px;
  right: -9px;
}
.App .wrapper-header-navbar .wrapper-navbar {
  width: 1366.6px;
  height: 45px;
  background-color: #fd6713;
  position: relative;
}
.App .wrapper-header-navbar .wrapper-navbar ul {
  width: 100%;
  height: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.App .wrapper-header-navbar .wrapper-navbar ul li {
  height: 100%;
  float: left;
  display: flex;
  align-items: center;
  padding: 0 27px;
  text-align: center;
  text-decoration: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
}
.App .wrapper-header-navbar .wrapper-navbar ul .active-menu {
  background-color: #faf6f2;
  color: #e85502;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav {
  width: 100%;
  height: 275px;
  position: absolute;
  background-color: #fff;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu1 {
  height: 100%;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu1 .wrapper-menu1-menu {
  margin-left: 168px;
  margin-top: 37px;
  margin-right: 260px;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu1 .wrapper-menu1-menu p {
  font-size: 16px;
  font-family: mulish;
  margin-top: 10px;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu1 .wrapper-menu1-image-1 {
  width: 200px;
  height: 196px;
  display: flex;
  flex-direction: column;
  margin-left: 120px;
  margin-top: 37px;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu1 .wrapper-menu1-image-1 .wrapper-image-1 {
  width: 200px;
  height: 152px;
  background-color: #cb5002;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu1 .wrapper-menu1-image-1 .wrapper-text-be-gai {
  margin-top: 20px;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu1 .wrapper-menu1-image-1 .wrapper-text-be-gai p {
  font-size: 16px;
  font-family: mavenpro;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu2 {
  width: 210px;
  height: 242px;
  display: flex;
  flex-direction: row;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu2 .wrapper-menu2-1 {
  display: flex;
  flex-direction: column;
  margin-left: 217px;
  margin-top: 32px;
  margin-right: 160px;
  cursor: pointer;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu2 .wrapper-menu2-1 .wrapper-menu2-image-text-1 {
  width: 180px;
  height: 160px;
  display: flex;
  flex-direction: column;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu2 .wrapper-menu2-1 .wrapper-menu2-image-text-1 .wrapper-image-1 {
  width: 100%;
  height: 166px;
  background-color: #cb5002;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu2 .wrapper-menu2-1 .wrapper-menu2-image-text-1 .wrapper-menu2-text-1 {
  width: 100%;
  height: 34px;
  background-color: #f6e2cd;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu2 .wrapper-menu2-1 .wrapper-menu2-image-text-1 .wrapper-menu2-text-1 p {
  font-family: asap;
  text-align: center;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu2 .wrapper-menu2-1 .wrapper-text-1 {
  width: 100%;
  height: 25px;
  margin-top: 20px;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu2 .wrapper-menu2-1 .wrapper-text-1 p {
  font-family: asap;
  color: #db2c2c;
  margin-left: 25px;
  letter-spacing: 5px;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu2 .wrapper-menu2-2 {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  margin-right: 160px;
  cursor: pointer;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu2 .wrapper-menu2-2 .wrapper-menu2-image-text-2 {
  width: 180px;
  height: 160px;
  display: flex;
  flex-direction: column;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu2 .wrapper-menu2-2 .wrapper-menu2-image-text-2 .wrapper-image-2 {
  width: 100%;
  height: 166px;
  background-color: #cb5002;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu2 .wrapper-menu2-2 .wrapper-menu2-image-text-2 .wrapper-menu2-text-2 {
  width: 100%;
  height: 34px;
  background-color: #f6e2cd;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu2 .wrapper-menu2-2 .wrapper-menu2-image-text-2 .wrapper-menu2-text-2 p {
  font-family: asap;
  text-align: center;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu2 .wrapper-menu2-2 .wrapper-text-2 {
  width: 100%;
  height: 25px;
  margin-top: 20px;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu2 .wrapper-menu2-2 .wrapper-text-2 p {
  font-family: asap;
  color: #db2c2c;
  margin-left: 25px;
  letter-spacing: 8px;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu2 .wrapper-menu2-3 {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  margin-right: 160px;
  cursor: pointer;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu2 .wrapper-menu2-3 .wrapper-menu2-image-text-3 {
  width: 180px;
  height: 160px;
  display: flex;
  flex-direction: column;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu2 .wrapper-menu2-3 .wrapper-menu2-image-text-3 .wrapper-image-3 {
  width: 100%;
  height: 166px;
  background-color: #cb5002;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu2 .wrapper-menu2-3 .wrapper-menu2-image-text-3 .wrapper-menu2-text-3 {
  width: 100%;
  height: 34px;
  background-color: #f6e2cd;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu2 .wrapper-menu2-3 .wrapper-menu2-image-text-3 .wrapper-menu2-text-3 p {
  font-family: asap;
  text-align: center;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu2 .wrapper-menu2-3 .wrapper-menu2-image-text-3 .wrapper-menu2-text-3 p .wrapper-menu2-2 {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  margin-right: 160px;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu2 .wrapper-menu2-3 .wrapper-menu2-image-text-3 .wrapper-menu2-text-3 p .wrapper-menu2-2 .wrapper-menu2-image-text-2 {
  width: 180px;
  height: 160px;
  display: flex;
  flex-direction: column;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu2 .wrapper-menu2-3 .wrapper-menu2-image-text-3 .wrapper-menu2-text-3 p .wrapper-menu2-2 .wrapper-menu2-image-text-2 .wrapper-image-2 {
  width: 100%;
  height: 166px;
  background-color: #cb5002;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu2 .wrapper-menu2-3 .wrapper-menu2-image-text-3 .wrapper-menu2-text-3 p .wrapper-menu2-2 .wrapper-menu2-image-text-2 .wrapper-menu2-text-2 {
  width: 100%;
  height: 34px;
  background-color: #f6e2cd;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu2 .wrapper-menu2-3 .wrapper-menu2-image-text-3 .wrapper-menu2-text-3 p .wrapper-menu2-2 .wrapper-menu2-image-text-2 .wrapper-menu2-text-2 p {
  font-family: asap;
  text-align: center;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu2 .wrapper-menu2-3 .wrapper-menu2-image-text-3 .wrapper-menu2-text-3 p .wrapper-menu2-2 .wrapper-text-2 {
  width: 100%;
  height: 25px;
  margin-top: 20px;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu2 .wrapper-menu2-3 .wrapper-menu2-image-text-3 .wrapper-menu2-text-3 p .wrapper-menu2-2 .wrapper-text-2 p {
  font-family: asap;
  color: #db2c2c;
  margin-left: 25px;
  letter-spacing: 5px;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu2 .wrapper-menu2-3 .wrapper-text-3 {
  width: 100%;
  height: 25px;
  margin-top: 20px;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu2 .wrapper-menu2-3 .wrapper-text-3 p {
  font-family: asap;
  color: #db2c2c;
  margin-left: 25px;
  letter-spacing: 9px;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu3 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu3 .wrapper-image {
  width: 100px;
  height: 75px;
  background-image: url("../asset/image/xetai.png");
  background-repeat: no-repeat;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu3 .wrapper-text {
  margin-top: 25px;
  font-family: asap;
  font-size: 16px;
}
.App .wrapper-header-navbar .wrapper-navbar .wrapper-subnav .wrapper-subnav-menu3 .wrapper-text span {
  text-decoration: underline;
  color: #ff3131;
}
.App .wrapper-search {
  width: 1366.6px;
  height: 538.6px;
  background-color: #f4d0ab;
}
.App .wrapper-notes {
  width: 1366.6px;
  height: 346.6px;
  background-color: #fff;
}
.App .wrapper-notes .wrapper-text-notes {
  margin-top: 60px;
}
.App .wrapper-notes .wrapper-text-notes h2 {
  font-size: 42px;
  color: #fd6713;
  font-family: notoserif;
  text-align: center;
}
.App .wrapper-notes .wrapper-text-notes p {
  font-size: 20px;
  color: #333;
  text-align: center;
  font-family: mulish;
}
.App .wrapper-notes .wrapper-text-thong-diep {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px 0;
}
.App .wrapper-notes .wrapper-text-thong-diep p {
  width: 500px;
  font-size: 20px;
  color: #333;
  text-align: center;
  font-family: mulish;
}
.App .wrapper-vccb {
  width: 1366.6px;
  height: 595.9px;
  background-color: #edefef;
  display: flex;
  flex-direction: column;
}
.App .wrapper-vccb .wrapper-vccb-text h2 {
  font-size: 38px;
  font-family: notoserif;
  font-weight: 600;
  text-align: center;
  margin-top: 42px;
}
.App .wrapper-vccb .wrapper-img-text-shop {
  width: 884px;
  height: 347px;
  display: flex;
  flex-direction: row;
  margin-top: 26px;
  margin-left: 140px;
}
.App .wrapper-vccb .wrapper-img-text-shop .wrapper-menu1 {
  width: 307px;
  height: 347px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 70px;
}
.App .wrapper-vccb .wrapper-img-text-shop .wrapper-menu1 .wrapper-img {
  width: 270px;
  height: 220px;
  background-color: #fff;
}
.App .wrapper-vccb .wrapper-img-text-shop .wrapper-menu1 .wrapper-text {
  width: 257px;
  height: 35px;
  margin-top: 18px;
}
.App .wrapper-vccb .wrapper-img-text-shop .wrapper-menu1 .wrapper-text p {
  font-size: 20px;
  font-family: notoserif;
  text-align: center;
}
.App .wrapper-vccb .wrapper-img-text-shop .wrapper-menu1 .wrapper-shop {
  width: 210px;
  height: 25px;
  background-color: #ff3131;
}
.App .wrapper-vccb .wrapper-img-text-shop .wrapper-menu1 .wrapper-shop p {
  color: #fff;
  font-family: mulish;
  font-size: 14px;
  text-align: center;
}
.App .wrapper-subscribe {
  width: 1366.6px;
  height: 287.6px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.App .wrapper-subscribe .wrapper-text h3 {
  font-size: 23px;
  font-family: notoserif;
  font-weight: 500;
  text-align: center;
}
.App .wrapper-subscribe .wrapper-text p {
  font-size: 16px;
  font-family: mulish;
  font-weight: 500;
  text-align: center;
  margin-top: 5px;
}
.App .wrapper-subscribe .wrapper-input {
  display: flex;
  flex-direction: row;
  margin-top: 25px;
}
.App .wrapper-subscribe .wrapper-input .wrapper-input-email input {
  width: 300px;
  height: 28px;
  border: 0.5px solid #333;
  background-color: #fffafa;
  padding: 0 18px;
  cursor: pointer;
}
.App .wrapper-subscribe .wrapper-input .wrapper-text-dki button {
  width: 100px;
  height: 30px;
  background-color: #fd6713;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}
.App .wrapper-shopnow3 {
  width: 1366.6px;
  height: 409.6px;
  display: flex;
  flex-direction: column;
}
.App .wrapper-shopnow3 .wrapper-notes-shopnow3 {
  width: 1366.6px;
  height: 331.5px;
  background-color: #f4d0ab;
  display: flex;
  justify-content: center;
  align-items: center;
}
.App .wrapper-shopnow3 .wrapper-notes-shopnow3 .wrapper-notes {
  width: 520px;
  height: 134px;
  background-color: #f6e2cd;
  display: flex;
  flex-direction: column;
}
.App .wrapper-shopnow3 .wrapper-notes-shopnow3 .wrapper-notes .wrapper-text {
  margin-top: 14px;
}
.App .wrapper-shopnow3 .wrapper-notes-shopnow3 .wrapper-notes .wrapper-text p {
  font-size: 25px;
  font-family: notoserif;
  text-align: center;
  font-weight: 600;
}
.App .wrapper-shopnow3 .wrapper-notes-shopnow3 .wrapper-notes .wrapper-shop3 {
  width: 200px;
  height: 40px;
  background-color: #ff3131;
  margin-top: 16px;
  margin-left: 170px;
}
.App .wrapper-shopnow3 .wrapper-notes-shopnow3 .wrapper-notes .wrapper-shop3 p {
  color: #fff;
  font-family: mulish;
  font-size: 16px;
  text-align: center;
  margin-top: 6px;
}
.App .wrapper-shopnow3 .wrapper-freeship {
  width: 1366.6px;
  height: 78.1px;
  background-color: #fd6713;
  display: flex;
  justify-content: center;
  align-items: center;
}
.App .wrapper-shopnow3 .wrapper-freeship p {
  color: #fff;
  font-size: 20px;
  font-family: asap;
}
.App .wrapper-shopnow3 .wrapper-freeship p span {
  color: #f6e2cd;
  text-decoration: underline;
}
.App .wrapper-carousel {
  width: 1366.6px;
  height: 653px;
  display: flex;
  flex-direction: column;
}
.App .wrapper-carousel .wrapper-bestseller {
  width: 1366.6px;
  height: 610px;
  display: flex;
  flex-direction: column;
  background-color: #edefef;
}
.App .wrapper-carousel .wrapper-bestseller .wrapper-bestseller-1 {
  width: 1366.6px;
  height: 100px;
  margin-top: 50px;
}
.App .wrapper-carousel .wrapper-bestseller .wrapper-bestseller-1 h3 {
  text-align: center;
  font-size: 42px;
  font-family: notoserif;
  font-weight: 400;
  color: #db2c2c;
}
.App .wrapper-carousel .wrapper-bestseller .wrapper-bestseller-2 {
  width: 1366.6px;
  height: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  text-align: right;
}
.App .wrapper-carousel .wrapper-bestseller .wrapper-bestseller-2 span {
  font-size: 16px;
  color: #e85502;
  text-decoration: underline;
  font-family: alata;
  margin-right: 73px;
}
.App .wrapper-carousel .wrapper-bestseller .wrapper-bestseller-3 {
  width: 1201px;
  height: 485px;
  margin-top: 30px;
  margin: 0 auto;
}
.App .wrapper-carousel .container-bestseller {
  width: 1366.6px;
  height: 43px;
  background-color: #fd6713;
}
.App .wrapper-shopnow4 {
  width: 1366.6px;
  height: 858.9px;
  background-color: #fff;
}
.App .wrapper-shopnow4 .wrapper-shopnow4-background {
  width: 600px;
  height: 600px;
  margin-top: 107px;
  margin-left: 160px;
  background-color: #edefef;
  position: relative;
}
.App .wrapper-shopnow4 .wrapper-shopnow4-background .container-shopnow4 {
  width: 430px;
  height: 260px;
  background-color: #fd6713;
  position: absolute;
  top: 170px;
  left: 500px;
}
.App .wrapper-shopnow4 .wrapper-shopnow4-background .container-shopnow4 .container-text {
  margin-top: 26px;
  margin-left: 40px;
}
.App .wrapper-shopnow4 .wrapper-shopnow4-background .container-shopnow4 .container-text .text-h3 {
  color: #fff;
  font-size: 32px;
  font-family: notoserif;
  font-weight: 600;
}
.App .wrapper-shopnow4 .wrapper-shopnow4-background .container-shopnow4 .container-text .text {
  color: #fff;
  font-size: 32px;
  font-family: notoserif;
  margin-left: 5px;
  font-weight: 600;
}
.App .wrapper-shopnow4 .wrapper-shopnow4-background .container-shopnow4 .container-p {
  margin-top: 30px;
}
.App .wrapper-shopnow4 .wrapper-shopnow4-background .container-shopnow4 .container-p p {
  color: #fff;
  font-size: 15px;
  text-align: center;
  font-family: cabin;
}
.App .wrapper-shopnow4 .wrapper-shopnow4-background .container-shopnow4 .container-button {
  width: 200px;
  height: 30px;
  background-color: #fff6f3;
  margin: 0 auto;
  margin-top: 30px;
}
.App .wrapper-shopnow4 .wrapper-shopnow4-background .container-shopnow4 .container-button button {
  width: 200px;
  height: 30px;
  color: #ff3131;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  font-family: cabin;
}
.App .wrapper-banner {
  width: 1366.6px;
  height: 357.5px;
  background-color: #f4d0ab;
  display: flex;
  justify-content: center;
  align-items: center;
}
.App .wrapper-banner p {
  font-family: notoserif;
  font-size: 25px;
}
.App .wrapper-bodyins {
  width: 1366.6px;
  height: 480px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.App .wrapper-bodyins .wrapper-body {
  width: 1179px;
  height: 410px;
  display: flex;
  flex-direction: column;
  background-color: #f0f1f1;
  justify-content: center;
  align-items: center;
}
.App .wrapper-bodyins .wrapper-body .wrapper-body-text {
  display: flex;
  flex-direction: column;
}
.App .wrapper-bodyins .wrapper-body .wrapper-body-text .wrapper-img-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.App .wrapper-bodyins .wrapper-body .wrapper-body-text .wrapper-img-text .wrapper-img {
  width: 50px;
  height: 50px;
  background-image: url("../asset/image/igtim.png");
  background-repeat: no-repeat;
}
.App .wrapper-bodyins .wrapper-body .wrapper-body-text .wrapper-img-text .text {
  margin-left: 8px;
}
.App .wrapper-bodyins .wrapper-body .wrapper-body-text .wrapper-img-text .text h3 {
  font-size: 21px;
  font-family: alata;
  font-weight: 200;
  text-align: center;
}
.App .wrapper-bodyins .wrapper-body .wrapper-body-text p {
  font-size: 16px;
  font-family: mulish;
  font-weight: 600;
  margin-top: 10px;
}
.App .wrapper-bodyins .wrapper-body .wrapper-body-img {
  display: flex;
  flex-direction: row;
  margin-top: 22px;
}
.App .wrapper-bodyins .wrapper-body .wrapper-body-img .body-img {
  width: 210px;
  height: 210px;
  background-color: #ff914d;
  margin-left: 20px;
}
.App .wrapper-faqs {
  width: 1366.6px;
  background-color: #edefef;
}
.App .wrapper-faqs .container-faqs {
  width: 1000px;
  display: flex;
  flex-direction: column;
  margin-left: 182px;
}
.App .wrapper-faqs .container-faqs .container-ship {
  width: 1000px;
  height: 467px;
  margin-top: 28px;
}
.App .wrapper-faqs .container-faqs .container-ship .container-text {
  font-size: 42px;
  font-family: notoserif;
  font-weight: 400;
  color: #e85502;
}
.App .wrapper-faqs .container-faqs .container-ship .text {
  font-size: 24px;
  font-family: notoserif;
  font-weight: 400;
  margin-top: 18px;
}
.App .wrapper-faqs .container-faqs .container-ship .wrapper-question-answer {
  width: 900px;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  border-bottom: 1px solid #333;
}
.App .wrapper-faqs .container-faqs .container-ship .wrapper-question-answer .wrapper-question {
  width: 900px;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.App .wrapper-faqs .container-faqs .container-ship .wrapper-question-answer .wrapper-question .question-text {
  font-size: 16px;
  font-family: cabin;
  font-weight: 700;
  margin-bottom: 16px;
}
.App .wrapper-faqs .container-faqs .container-ship .wrapper-question-answer .wrapper-question .question-svg {
  cursor: pointer;
}
.App .wrapper-faqs .container-faqs .container-ship .wrapper-question-answer .answer-text {
  font-size: 16px;
  font-family: mulish;
  font-weight: 400;
  margin-bottom: 16px;
}
.App .wrapper-faqs .container-faqs .container-ship .wrapper-question-answer-border-none {
  width: 900px;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
.App .wrapper-faqs .container-faqs .container-ship .wrapper-question-answer-border-none .wrapper-question {
  width: 900px;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.App .wrapper-faqs .container-faqs .container-ship .wrapper-question-answer-border-none .wrapper-question .question-text {
  font-size: 16px;
  font-family: cabin;
  font-weight: 700;
  margin-bottom: 16px;
}
.App .wrapper-faqs .container-faqs .container-ship .wrapper-question-answer-border-none .wrapper-question .question-svg {
  cursor: pointer;
}
.App .wrapper-faqs .container-faqs .container-ship .wrapper-question-answer-border-none .answer-text {
  font-size: 16px;
  font-family: mulish;
  font-weight: 400;
  margin-bottom: 16px;
}
.App .wrapper-faqs .container-faqs .container-san-pham {
  width: 1000px;
  display: flex;
  flex-direction: column;
  margin-top: 81px;
}
.App .wrapper-faqs .container-faqs .container-san-pham h3 {
  font-size: 24px;
  font-family: notoserif;
  font-weight: 500;
}
.App .wrapper-faqs .container-faqs .container-san-pham .wrapper-question-answer {
  width: 900px;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  border-bottom: 1px solid #333;
}
.App .wrapper-faqs .container-faqs .container-san-pham .wrapper-question-answer .wrapper-question {
  width: 900px;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.App .wrapper-faqs .container-faqs .container-san-pham .wrapper-question-answer .wrapper-question .question-text {
  font-size: 16px;
  font-family: cabin;
  font-weight: 700;
  margin-bottom: 16px;
}
.App .wrapper-faqs .container-faqs .container-san-pham .wrapper-question-answer .wrapper-question .question-svg {
  cursor: pointer;
}
.App .wrapper-faqs .container-faqs .container-san-pham .wrapper-question-answer .answer-text {
  font-size: 16px;
  font-family: mulish;
  font-weight: 400;
  margin-bottom: 16px;
}
.App .wrapper-faqs .container-faqs .container-san-pham .wrapper-question-answer-border-none {
  width: 900px;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
.App .wrapper-faqs .container-faqs .container-san-pham .wrapper-question-answer-border-none .wrapper-question {
  width: 900px;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.App .wrapper-faqs .container-faqs .container-san-pham .wrapper-question-answer-border-none .wrapper-question .question-text {
  font-size: 16px;
  font-family: cabin;
  font-weight: 700;
  margin-bottom: 16px;
}
.App .wrapper-faqs .container-faqs .container-san-pham .wrapper-question-answer-border-none .wrapper-question .question-svg {
  cursor: pointer;
}
.App .wrapper-faqs .container-faqs .container-san-pham .wrapper-question-answer-border-none .answer-text {
  font-size: 16px;
  font-family: mulish;
  font-weight: 400;
  margin-bottom: 16px;
}
.App .wrapper-faqs .container-faqs .wrapper-span {
  width: 900px;
  height: auto;
  margin-bottom: 92px;
  margin-top: 38px;
}
.App .wrapper-faqs .container-faqs .wrapper-span a {
  font-size: 16px;
  font-family: cabin;
  text-decoration: underline;
  color: #db2c2c;
  font-style: italic;
  cursor: pointer;
}
.App .wrapper-footer {
  width: 1366.6px;
  height: 428.6px;
  background-color: #fd6713;
  display: flex;
  flex-direction: column;
}
.App .wrapper-footer .container-footer {
  width: 1366.6px;
  height: 366px;
  display: flex;
  flex-direction: column;
  margin-left: 169px;
}
.App .wrapper-footer .container-footer .container-text {
  width: 1366.6px;
  height: 157px;
  margin-top: 28px;
}
.App .wrapper-footer .container-footer .container-text h3 {
  font-size: 36px;
  color: #fff;
  font-family: notoserif;
  margin-bottom: 9px;
}
.App .wrapper-footer .container-footer .container-text .hr {
  border-top: 1px solid #fff;
  width: 500px;
}
.App .wrapper-footer .container-footer .container-text p {
  font-size: 18px;
  color: #fff;
  font-family: mavenpro;
  margin-top: 9px;
  margin-left: 5px;
}
.App .wrapper-footer .container-footer .container-lien-he-faqs {
  width: 1366.6px;
  height: 202px;
  display: flex;
  flex-direction: row;
}
.App .wrapper-footer .container-footer .container-lien-he-faqs .container-lien-he {
  width: 554.5px;
  height: 202px;
  margin-top: 20px;
}
.App .wrapper-footer .container-footer .container-lien-he-faqs .container-lien-he h3 {
  font-size: 32px;
  font-family: notoserif;
  color: #fff;
  font-weight: 500;
}
.App .wrapper-footer .container-footer .container-lien-he-faqs .container-lien-he p {
  color: #fff;
  font-family: mulish;
  margin-top: 2px;
  font-size: 18px;
}
.App .wrapper-footer .container-footer .container-lien-he-faqs .container-img-zalo {
  width: 812.1px;
  height: 202px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 200px;
  margin-top: 20px;
}
.App .wrapper-footer .container-footer .container-lien-he-faqs .container-img-zalo .container-img {
  background-image: url("../asset/image/footer.png");
  background-repeat: no-repeat;
  width: 110px;
  height: 100px;
  margin-right: 10px;
}
.App .wrapper-footer .container-footer .container-lien-he-faqs .container-img-zalo p {
  color: #fff;
  font-family: movenpro;
  margin-top: 11px;
  font-size: 18px;
}
.App .wrapper-footer .container-hr {
  width: 1366.6px;
  height: 62px;
  border-top: 1px solid #fff;
}

.open-faq {
  animation: open 0.5s linear 0s 1 normal forwards;
}
@keyframes open {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(90deg);
  }
}

.close-faq {
  animation: close 0.5s linear 0s 1 normal forwards;
}
@keyframes close {
  from {
    transform: rotate(90deg);
  }
  to {
    transform: rotate(0);
  }
}

.display-flex {
  display: flex;
}

.display-none {
  display: none;
}/*# sourceMappingURL=wobble.css.map */